/*.fc-toolbar-title {*/
/*    font-size: 1.5em;*/
/*    color: #333;*/
/*}*/

/*.fc-button {*/
/*    background-color: #a6124f;*/
/*    color: white;*/
/*    border: none;*/
/*    border-radius: 3px;*/
/*}*/

/*.fc-button:hover {*/
/*    background-color: #0D47A1;*/
/*}*/

.fc-col-header-cell {
    background-color: #4285f4;
    color: white;
    font-weight: bold;
}

.fc-timegrid-slot {
    background-color: #f3f3f3;
}

.fc-event {
    background-color: #4285f4;
    border-color: black;
    border-radius: 4px;
    overflow: hidden;
}

.fc-event:hover {
    background-color: #7e0865;
    cursor: pointer;
}


.event-alberto {
    background-color: #4285f4;
}
