@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
